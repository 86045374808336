import "./DesktopShare.css";

import { Modal, Fade, makeStyles, Backdrop } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SocialIcons from "../../../components/Share/SocialIcons";
import { useEffect, useRef, useState } from "react";
import { DownloadIcon } from "../../../images/share-icons";
import CopyAnimation from "../CopyAnimation";
import { saveAs } from "file-saver";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    maxWidth: "50%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    gap: "40px",
    flexDirection: "column",
    borderRadius: "20px",
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 7, 8),
    backgroundColor: theme.palette.background.paper,
  },
}));

const DesktopShare = ({
  blob,
  linkUrl,
  imageUrl,
  ready,
  setReady,
  title = "Share an app with your friends",
  platforms = [],
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const classes = useStyles();
  const ref = useRef(null);

  //const url = window.location.href;

  useEffect(() => {
    if (!ready) {
      setTimeout(() => {
        setIsCopied(false);
      }, 300);
    }
  }, [ready]);

  //CopyToClipBoard--> gets a parameter and copy it to the ClipBoard
  const copyToClipBoard = async () => {
    try {
      // Clipboard feature is available only in secure contexts (HTTPS), in some or all supporting browsers.
      await navigator.clipboard.writeText(linkUrl);
      setIsCopied("✓");
    } catch (err) {
      console.log(err);
    }
  };

  const downloadImage = (imageUrl) => {
    saveAs(blob, "PredictionSnapshot.jpg");
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={ready}
      onClose={() => setReady(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={ready}>
        <div className={classes.paper}>
          <button
            onClick={() => setReady(false)}
            className="exitBtn__DesktopShare"
          >
            <CloseIcon />
          </button>
          <div>
            <h6 className="title__DesktopShare" id="transition-modal-title">
              {title}
            </h6>
          </div>
          {linkUrl && (
            <div className="copy_link_box__DesktopShare">
              <div
                className="url2Share__DesktopShare"
                id="transition-modal-description"
              >
                <a href={linkUrl} target="_blank" rel="noreferrer">
                  {linkUrl}
                </a>
              </div>
              <div onClick={copyToClipBoard} className="copyBtn__DesktopShare">
                {isCopied ? <CopyAnimation /> : <div>Copiar</div>}
              </div>
            </div>
          )}
          {imageUrl && (
            <div ref={ref} className="snapShot_Box__DesktopShare">
              <div className="img_box__DesktopShare">
                <img
                  className="snapshot__DesktopShare"
                  src={imageUrl}
                  alt="predictions snapshot"
                />
                <div
                  onClick={() =>
                    // data:image/jpg;base64,
                    downloadImage(imageUrl)
                  }
                  className="blackSlip__DesktopShare"
                >
                  <img src={DownloadIcon} alt="download snapshot" />
                  <div className="blackSlipText__DesktopShare">Download</div>
                </div>
              </div>
            </div>
          )}
          <div className="socialIcons_container__DesktopShare">
            <SocialIcons linkUrl={linkUrl? linkUrl : imageUrl}  platforms={platforms}  />
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default DesktopShare;
