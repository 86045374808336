let BASE_URL = "https://qa.hook-apps.com";
// let BASE_URL = "http://localhost:8080";
let LOGIN_URL_PROD =
  "http://q.mx.bet.cdrsbg.com/deportescolombia_MVP/#/HomePage?openlogin=true&afterloginurl=MvpCoderePage&partner=mvp";

export { BASE_URL, LOGIN_URL_PROD };

export const shareUrlWithFriends = "https://m.codere.com.co/";
// export const oneLinkStore = "Here will be one link for going to store"

