import React from "react";
import Nav from "./Nav";
import Banner from "../GeneralComponents/Banner";
import Round from "../Matches/Round";
import MatchesTabs from "./MatchesTabs";
import AccumulatorTabs from "./AccumulatorTabs";
import MediaQuery from "react-responsive";
import DesktopHeader from "./DesktopHeader";
import PagesEnum from "../enums/pages";

const Header = ({
  pageName,
  banner,
  round,
  league,
  userAddedPredictions,
  bgColor,
  textColor,
  lastRound = false,
}) => {
  return (
    // if not inside prediction page (where we send banner) - style header differently
    <div
    className={
      !banner
        ? `header-container bg-white header-banner`
        : "border-bottom header-banner"
    }
    style={{
      paddingTop: `env(safe-area-inset-top, 40px)`,
      backgroundColor: "#334049",
      position:"fixed",
      top:0,
      left:0,
      right:0,
      zIndex:1030
    }}
  >
      <MediaQuery minWidth={768}>
        <DesktopHeader
          pageName={pageName}
          bgColor={bgColor}
          textColor={textColor}
        />
      </MediaQuery>
      <MediaQuery maxWidth={768}>
        <Nav banner={banner} pageName={pageName}></Nav>
      </MediaQuery>
      {/* if inside accumulator page - show banner */}
      {banner ? <Banner pageName={pageName} banner={banner}></Banner> : null}

      {pageName === PagesEnum.parlays ? (
        <AccumulatorTabs league={league}></AccumulatorTabs>
      ) : null}
      {/* <MediaQuery minWidth={768}>
        {banner ? <Banner banner={banner}></Banner> : null}
      </MediaQuery> */}
      <MediaQuery maxWidth={768}>
        {pageName === PagesEnum.predictions ? (
          <MatchesTabs></MatchesTabs>
        ) : null}
      </MediaQuery>

      {pageName === PagesEnum.predictions && (
        <Round
          currentRound={round}
          userAddedPredictions={userAddedPredictions}
          lastRound={lastRound}
        ></Round>
      )}
      <MediaQuery minWidth={768}>
        {pageName === PagesEnum.predictions ? (
          <div
            className="bg-white"
            style={{
              width: 400,
            }}
          >
            <MatchesTabs bigScreen={true}></MatchesTabs>
          </div>
        ) : null}
      </MediaQuery>
    </div>
  );
};

export default Header;
