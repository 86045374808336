import React from "react";
import { Modal, Fade, makeStyles, Backdrop } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "20px",
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container_btn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "20px",
  },
  btn: {
    "&:hover, &active, &focus": {
      backgroundColor: "#7ebc15",
      cursor: "pointer",
      color: "white",
    },
    width: "80px",
    height: "30px",
    border: "1px solid #7ebc15",
    borderRadius: "20px",
    color: "#7ebc15",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
const ModalExit = ({ ready, setReady, back }) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={ready}
      onClose={() => setReady(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={ready}>
        <div className={classes.paper}>
          <h4 className="text-center" id="transition-modal-title">
            Quit from the page?
          </h4>
          <p id="transition-modal-description">
            All progress in this page will be lost.
          </p>
          <div className={classes.container_btn}>
            <div onClick={back} className={classes.btn}>
              <p className="pt-3">LEAVE</p>
            </div>
            <div onClick={() => setReady(false)} className={classes.btn}>
              <p className="pt-3">STAY</p>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalExit;
