import React, { useContext } from "react";
import { PredictionsContext } from "../contexts/PredictionsContext";
import editIcon from "../images/header/editIcon.svg";
import editIconDisabled from "../images/header/editIconDisabled.svg";
import { useLocation } from "react-router-dom";
import { CURRENT_CAMPAIGN_ENDPOINT } from "../leagues/leagues";

function EditBtn({ userAddedPredictions, editMode, setEditMode }) {
  const { spanish_RoundNumber_CurrentRound, english_RoundNumber_CurrentRound } =
    useContext(PredictionsContext);

  const location = useLocation();

  const hasRoundStarted = !!(
    location.pathname === `/${CURRENT_CAMPAIGN_ENDPOINT}` &&
    spanish_RoundNumber_CurrentRound
  );

  return (
    <div data-html2canvas-ignore>
      {userAddedPredictions && !hasRoundStarted && (
        <>
          <input
            disabled={editMode}
            checked={editMode}
            onChange={() => {
              console.log(editMode);
              setEditMode(!editMode);
            }}
            className="editCheckbox"
            hidden
            type="checkbox"
            id="edit"
            name="edit"
          />
          <label htmlFor="edit" className="editButton">
            <img src={editMode ? editIconDisabled : editIcon} />
            <span>Editar</span>
          </label>
        </>
      )}
    </div>
  );
}

export default EditBtn;
