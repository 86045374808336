export const leaguesEndpoints = {
  LEAGUE1: "primera-a",
  LEAGUE2: "premier-league",
}

export const campaignEndpoints = {
  CAMPAIGN: "colCodere"
}

export const leaguesIds = {
  ID_MEXICO: "631",
  ID_NORWAY: "463",
  ID_ENGLISH: "237",
  ID_COLOMBIA: "746",
}

export const CURRENT_LEAGUE1 = leaguesIds.ID_COLOMBIA
export const CURRENT_LEAGUE_ENDPOINT_1 = leaguesEndpoints.LEAGUE1
export const CURRENT_LEAGUE_ENDPOINT_2 = leaguesEndpoints.LEAGUE2
export const CURRENT_CAMPAIGN_ENDPOINT = campaignEndpoints.CAMPAIGN

export const CURRENT_CAMPAIGN_ID = 3